<template>
  <div class="login-container">
    <div class="login-card">
      <div class="login-header">
        <img src="../assets/Logo.jpg" alt="QUAGG Logo" class="logo">
        <h1>QUAGG Engineering</h1>
        <p>Interner Bereich</p>
      </div>

      <form @submit.prevent="handleLogin" class="login-form">
        <div class="form-group">
          <label for="username">Benutzername</label>
          <input 
            id="username"
            v-model="username"
            type="text"
            required
            autocomplete="username"
          >
        </div>

        <div class="form-group">
          <label for="password">Passwort</label>
          <input 
            id="password"
            v-model="password"
            type="password"
            required
            autocomplete="current-password"
          >
        </div>

        <div v-if="error" class="error-message">
          {{ error }}
        </div>

        <button type="submit" class="login-button">
          Anmelden
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: null
    }
  },
  methods: {
    async handleLogin() {
      try {
        // For now, just redirect to the internal domain with basic auth
        const credentials = btoa(`${this.username}:${this.password}`);
        window.location.href = `https://intern.quagg-engineering.org?auth=${credentials}`;
      } catch (error) {
        this.error = 'Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.';
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
}

.login-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.logo {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

.login-header h1 {
  color: #2196F3;
  margin: 0;
  font-size: 1.5rem;
}

.login-header p {
  color: #666;
  margin-top: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #2196F3;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #1976D2;
}

.error-message {
  color: #f44336;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
}
</style>