<template>
  <div class="project-list">
    <!-- Add button section above filters -->
    <div class="header-section">
      <h2>Projekte</h2>
      <button class="new-project-btn" @click="openProjectBuilder">
        <span class="plus-icon">+</span>
        Neues Projekt erstellen
      </button>
    </div>

    <!-- Search and Filter Bar -->
    <div class="filter-section">
      <div class="search-bar">
        <input 
          type="text" 
          v-model="searchQuery" 
          placeholder="Projekte suchen..."
          @input="filterProjects"
        >
      </div>
      
      <div class="status-filters">
        <button 
          v-for="status in statusOptions" 
          :key="status.value"
          :class="['status-filter', { active: selectedStatus === status.value }]"
          @click="selectStatus(status.value)"
        >
          <span class="status-dot" :style="{ backgroundColor: status.color }"></span>
          {{ status.label }}
        </button>
      </div>
    </div>

    <!-- Project Grid -->
    <div class="project-grid">
      <div v-for="project in filteredProjects" 
           :key="project.id" 
           class="project-card"
           @click="openProject(project)">
        <div class="project-header">
          <h3>{{ project.name }}</h3>
          <span class="project-number">#{{ project.number }}</span>
        </div>
        <div class="project-details">
          <p>{{ project.description }}</p>
          <div class="project-footer">
            <span class="project-date">{{ formatDate(project.date) }}</span>
            <div class="project-actions">
              <span :class="['status-badge', project.status]">
                {{ getStatusLabel(project.status) }}
              </span>
              <button class="status-change-btn" @click.stop="openStatusModal(project)">
                <span class="icon">📝</span>
                Status
              </button>
            </div>
          </div>
        </div>

        <project-status
          v-if="selectedProject && selectedProject.id === project.id"
          :project-id="project.id"
          :current-status="project.status"
          @status-updated="handleStatusUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectStatus from './project_status.vue'

export default {
  name: 'ProjectList',
  components: {
    ProjectStatus
  },
  data() {
    return {
      projects: [],
      searchQuery: '',
      selectedStatus: 'all',
      filteredProjects: [],
      statusOptions: [
        { value: 'all', label: 'Alle', color: '#666' },
        { value: 'urgent', label: '🔥 Wichtig', color: '#D32F2F' },
        { value: 'active', label: 'Laufend', color: '#4CAF50' },
        { value: 'completed', label: 'Abgeschlossen', color: '#2196F3' },
        { value: 'paused', label: 'Pausiert', color: '#FFC107' },
        { value: 'applicant', label: 'Bewerber', color: '#9C27B0' },
        { value: 'rejected', label: 'Abgelehnt', color: '#F44336' }
      ],
      selectedProject: null
    }
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/projects`);
        if (!response.ok) throw new Error('Failed to fetch projects');
        this.projects = await response.json();
        
        // Add dummy status for demonstration with new options
        this.projects = this.projects.map(project => ({
          ...project,
          status: ['urgent', 'active', 'completed', 'paused', 'applicant', 'rejected'][Math.floor(Math.random() * 6)]
        }));
        
        this.filterProjects();
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    filterProjects() {
      const query = this.searchQuery.toLowerCase();
      this.filteredProjects = this.projects.filter(project => {
        const matchesSearch = 
          project.name.toLowerCase().includes(query) ||
          project.number.toLowerCase().includes(query) ||
          project.description.toLowerCase().includes(query);
          
        const matchesStatus = 
          this.selectedStatus === 'all' || 
          project.status === this.selectedStatus;
          
        return matchesSearch && matchesStatus;
      });
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('de-DE');
    },
    openProject(project) {
      window.location.href = `/inspection?project=${project.id}`;
    },
    selectStatus(status) {
      this.selectedStatus = status;
      this.filterProjects();
    },
    getStatusLabel(status) {
      const statusMap = {
        urgent: '🔥 Wichtig',
        active: 'Laufend',
        completed: 'Abgeschlossen',
        paused: 'Pausiert',
        applicant: 'Bewerber',
        rejected: 'Abgelehnt'
      };
      return statusMap[status] || status;
    },
    openProjectBuilder() {
      window.open('/project-build', '_blank');
    },
    openStatusModal(project) {
      this.selectedProject = project;
    },
    handleStatusUpdate(updateData) {
      if (this.selectedProject) {
        this.selectedProject.status = updateData.status;
        const index = this.projects.findIndex(p => p.id === this.selectedProject.id);
        if (index !== -1) {
          this.projects[index] = { ...this.projects[index], ...updateData };
        }
        this.selectedProject = null;
        this.filterProjects();
      }
    }
  },
  mounted() {
    this.fetchProjects();
  }
}
</script>

<style scoped>
.project-list {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.new-project-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.new-project-btn:hover {
  background-color: #1976D2;
  transform: translateY(-2px);
}

.plus-icon {
  font-size: 20px;
  font-weight: bold;
}

.filter-section {
  margin-bottom: 30px;
}

.search-bar {
  margin-bottom: 15px;
}

.search-bar input {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.search-bar input:focus {
  border-color: #2196F3;
  outline: none;
}

.status-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.status-filter {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background: #f5f5f5;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-filter:hover {
  background: #e0e0e0;
}

.status-filter.active {
  background: #e3f2fd;
  color: #1976D2;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.project-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.project-header h3 {
  margin: 0;
  color: #333;
}

.project-number {
  color: #666;
  font-size: 0.9em;
}

.project-details {
  color: #666;
}

.project-details p {
  margin: 0 0 10px 0;
}

.project-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.project-date {
  font-size: 0.8em;
  color: #888;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: 500;
}

.status-badge.urgent {
  background: #FFEBEE;
  color: #D32F2F;
  font-weight: 600;
}

.status-badge.active {
  background: #E8F5E9;
  color: #2E7D32;
}

.status-badge.completed {
  background: #E3F2FD;
  color: #1565C0;
}

.status-badge.paused {
  background: #FFF8E1;
  color: #F57F17;
}

.status-badge.applicant {
  background: #F3E5F5;
  color: #7B1FA2;
}

.status-badge.rejected {
  background: #FFEBEE;
  color: #C62828;
}

.project-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-change-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  font-size: 0.9em;
  transition: all 0.2s;
}

.status-change-btn:hover {
  background: #f5f5f5;
  border-color: #2196F3;
}

.status-change-btn .icon {
  font-size: 1.1em;
}
</style>
