<template>
  <div class="status-manager">
    <!-- Status Change Button -->
    <button class="status-btn" @click="showStatusModal = true">
      <span class="status-indicator" :class="currentStatus"></span>
      Status ändern
    </button>

    <!-- Status Change Modal -->
    <div v-if="showStatusModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Projektstatus aktualisieren</h3>
          <button class="close-btn" @click="showStatusModal = false">&times;</button>
        </div>

        <div class="status-form">
          <div class="form-group">
            <label>Status</label>
            <select v-model="newStatus">
              <option value="urgent">🔥 Wichtig</option>
              <option value="active">Laufend</option>
              <option value="completed">Abgeschlossen</option>
              <option value="paused">Pausiert</option>
              <option value="applicant">Bewerber</option>
              <option value="rejected">Abgelehnt</option>
            </select>
          </div>

          <div class="form-group">
            <label>Grund der Änderung</label>
            <textarea v-model="statusNote" rows="3" placeholder="Grund für die Statusänderung..."></textarea>
          </div>

          <!-- Contact Updates -->
          <div class="contact-section">
            <h4>Ansprechpartner aktualisieren</h4>
            <div v-for="(contact, index) in contacts" :key="index" class="contact-item">
              <div class="contact-header">
                <h5>Ansprechpartner {{ index + 1 }}</h5>
                <button class="remove-btn" @click="removeContact(index)">&times;</button>
              </div>
              <div class="contact-fields">
                <input v-model="contact.name" placeholder="Name">
                <input v-model="contact.role" placeholder="Position">
                <input v-model="contact.phone" placeholder="Telefon">
                <input v-model="contact.email" placeholder="Email">
              </div>
            </div>
            <button class="add-contact-btn" @click="addContact">
              + Ansprechpartner hinzufügen
            </button>
          </div>

          <!-- Additional Notes -->
          <div class="form-group">
            <label>Zusätzliche Notizen</label>
            <textarea v-model="additionalNotes" rows="4" placeholder="Weitere wichtige Informationen..."></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button class="cancel-btn" @click="showStatusModal = false">Abbrechen</button>
          <button class="save-btn" @click="saveChanges">Änderungen speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectStatus',
  props: {
    projectId: {
      type: Number,
      required: true
    },
    currentStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showStatusModal: false,
      newStatus: '',
      statusNote: '',
      additionalNotes: '',
      contacts: [],
      statusHistory: []
    }
  },
  methods: {
    addContact() {
      this.contacts.push({
        name: '',
        role: '',
        phone: '',
        email: ''
      });
    },
    removeContact(index) {
      this.contacts.splice(index, 1);
    },
    async saveChanges() {
      try {
        const updateData = {
          status: this.newStatus,
          statusNote: this.statusNote,
          additionalNotes: this.additionalNotes,
          contacts: this.contacts,
          updatedAt: new Date().toISOString()
        };

        const response = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/projects/${this.projectId}/status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });

        if (!response.ok) throw new Error('Failed to update project status');

        this.$emit('status-updated', updateData);
        this.showStatusModal = false;
      } catch (error) {
        console.error('Error updating project status:', error);
      }
    }
  },
  mounted() {
    this.newStatus = this.currentStatus;
  }
}
</script>

<style scoped>
.status-manager {
  margin: 20px 0;
}

.status-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
}

.status-btn:hover {
  border-color: #2196F3;
  background: #f5f5f5;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-indicator.urgent { background: #D32F2F; }
.status-indicator.active { background: #4CAF50; }
.status-indicator.completed { background: #2196F3; }
.status-indicator.paused { background: #FFC107; }
.status-indicator.applicant { background: #9C27B0; }
.status-indicator.rejected { background: #F44336; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-form {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.contact-section {
  margin: 20px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.contact-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.contact-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.add-contact-btn {
  width: 100%;
  padding: 10px;
  background: #e3f2fd;
  color: #1976D2;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-btn, .cancel-btn {
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.save-btn {
  background: #2196F3;
  color: white;
  border: none;
}

.cancel-btn {
  background: #f5f5f5;
  color: #666;
  border: none;
}

input, select, textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.remove-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}
</style>
