<template>
  <div class="project-builder">
    <div class="builder-header">
      <h1>Neues Projekt erstellen</h1>
    </div>

    <form class="builder-form" @submit.prevent="createProject">
      <!-- Basis Information -->
      <div class="form-section">
        <h3>Basis Information</h3>
        <div class="form-group">
          <label>Projektname*</label>
          <input v-model="project.name" required>
        </div>
        <div class="form-group">
          <label>Projektnummer*</label>
          <input v-model="project.number" required>
        </div>
        <div class="form-group">
          <label>Status</label>
          <select v-model="project.status">
            <option value="urgent">🔥 Wichtig</option>
            <option value="active">Laufend</option>
            <option value="paused">Pausiert</option>
            <option value="applicant">Bewerber</option>
          </select>
        </div>
        <div class="form-group">
          <label>Startdatum*</label>
          <input type="date" v-model="project.date" required>
        </div>
      </div>

      <!-- Auftraggeber Information -->
      <div class="form-section">
        <h3>Auftraggeber</h3>
        <div class="form-group">
          <label>Auftraggeber*</label>
          <input v-model="project.client" required>
        </div>
        <div class="form-group">
          <label>Ansprechpartner</label>
          <input v-model="project.contactPerson">
        </div>
        <div class="form-group">
          <label>Telefon</label>
          <input v-model="project.phone" type="tel">
        </div>
        <div class="form-group">
          <label>Email</label>
          <input v-model="project.email" type="email">
        </div>
      </div>

      <!-- Projekt Details -->
      <div class="form-section">
        <h3>Projekt Details</h3>
        <div class="form-group">
          <label>Leistungsphase</label>
          <select v-model="project.phase">
            <option value="1">LP 1 - Grundlagenermittlung</option>
            <option value="2">LP 2 - Vorplanung</option>
            <option value="3">LP 3 - Entwurfsplanung</option>
            <option value="4">LP 4 - Genehmigungsplanung</option>
            <option value="5">LP 5 - Ausführungsplanung</option>
            <option value="6">LP 6 - Vorbereitung Vergabe</option>
            <option value="7">LP 7 - Mitwirkung Vergabe</option>
            <option value="8">LP 8 - Objektüberwachung</option>
            <option value="9">LP 9 - Objektbetreuung</option>
          </select>
        </div>
        <div class="form-group">
          <label>Geschätztes Volumen (€)</label>
          <input v-model="project.estimatedVolume" type="number">
        </div>
        <div class="form-group">
          <label>Projektstandort</label>
          <input v-model="project.location">
        </div>
        <div class="form-group">
          <label>Projekttyp</label>
          <select v-model="project.type">
            <option value="neubau">Neubau</option>
            <option value="sanierung">Sanierung</option>
            <option value="umbau">Umbau</option>
            <option value="erweiterung">Erweiterung</option>
          </select>
        </div>
      </div>

      <!-- Zusätzliche Information -->
      <div class="form-section">
        <h3>Zusätzliche Information</h3>
        <div class="form-group">
          <label>Priorität</label>
          <select v-model="project.priority">
            <option value="high">Hoch</option>
            <option value="medium">Mittel</option>
            <option value="low">Niedrig</option>
          </select>
        </div>
        <div class="form-group">
          <label>Geplantes Enddatum</label>
          <input type="date" v-model="project.endDate">
        </div>
        <div class="form-group full-width">
          <label>Beschreibung</label>
          <textarea v-model="project.description" rows="4"></textarea>
        </div>
        <div class="form-group full-width">
          <label>Notizen</label>
          <textarea v-model="project.notes" rows="4"></textarea>
        </div>
      </div>

      <div class="form-actions">
        <button type="button" class="cancel-btn" @click="cancel">Abbrechen</button>
        <button type="submit" class="submit-btn">Projekt erstellen</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ProjectBuilder',
  data() {
    return {
      project: {
        // Basic Info
        name: '',
        number: '',
        status: 'active',
        date: new Date().toISOString().split('T')[0],
        
        // Client Info
        client: '',
        contactPerson: '',
        phone: '',
        email: '',
        
        // Project Details
        phase: '1',
        estimatedVolume: '',
        location: '',
        type: 'neubau',
        
        // Additional Info
        priority: 'medium',
        endDate: '',
        description: '',
        notes: ''
      }
    }
  },
  methods: {
    async createProject() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/FastAPI/projects`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.project)
        });

        if (!response.ok) throw new Error('Failed to create project');
        
        // Redirect to project list
        window.location.href = '/projects';
      } catch (error) {
        console.error('Error creating project:', error);
      }
    },
    cancel() {
      window.close();
    }
  }
}
</script>

<style scoped>
.project-builder {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.builder-header {
  margin-bottom: 30px;
}

.builder-form {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
}

.form-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.form-section h3 {
  color: #2196F3;
  font-size: 1.2em;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e3f2fd;
}

.form-group {
  margin-bottom: 20px;
  width: calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

.form-group:nth-child(2n) {
  margin-right: 0;
}

.form-group.full-width {
  width: 100%;
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #666;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s;
}

input:focus, select:focus, textarea:focus {
  border-color: #2196F3;
  outline: none;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 30px;
}

.submit-btn, .cancel-btn {
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
}

.submit-btn {
  background: #2196F3;
  color: white;
  border: none;
}

.submit-btn:hover {
  background: #1976D2;
}

.cancel-btn {
  background: #f5f5f5;
  color: #666;
  border: none;
}

.cancel-btn:hover {
  background: #e0e0e0;
}
</style>
