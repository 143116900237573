<template>
  <div class="start-button-container">
    <button class="start-button" @click="openNewTab">
      Wähle Projekte
    </button>
  </div>
</template>

<script>
export default {
  name: 'StartButton',
  methods: {
    openNewTab() {
      window.open('/project-list', '_blank');
    }
  }
}
</script>

<style scoped>
.start-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.start-button {
  padding: 20px 40px;
  font-size: 1.2em;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.start-button:hover {
  background-color: #1976D2;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.start-button:active {
  transform: translateY(0);
}
</style>
